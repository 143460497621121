<template>
 <v-container>
    <div> Component 시험 페이지</div>
    <div class="chart_Layout">
      <!-- <span class="title">암모니아</span> -->
      <Bar
        :chart-options="options"
        :chart-data="chartData"
        :height="200"
      />
    </div>

 </v-container>
</template>

<script>
  // import Apis from '@/api/apis'

  import { Bar }  from 'vue-chartjs/legacy'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  import zoomPlugin from 'chartjs-plugin-zoom';

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, zoomPlugin)



  export default {
    name: 'ComponentTest',
    components: { Bar },
    props: {
      plugins: {
      type: Array,
      default: () => []
      }
    },

    methods: {
    },  // -----------  methods 
    data() {
      // console.log(this)
      // console.log(this.$store)
      // console.log(this.$data)


      return {
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Chart.js Bar Chart - Stacked'
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        },
        chartData: {
          labels: ['농장평균배출량', '내농장배출량', '저탄소농장평균배출량'],
          datasets: [
            {
              label: '장내발효',
              backgroundColor: '#D2E4D6',
              data: [100, 70, 50]
            },
            {
              label: '분뇨처리',
              backgroundColor: '#90B499',
              data: [100, 70, 50]
            },
            {
              label: '에너지',
              backgroundColor: '#C6E4F5',
              data: [100, 70, 50]
            },
            {
              label: '그외',
              backgroundColor: '#82B2CA',
              data: [50, 30, 20]
            }
          ],
        }

        // chartData: {
        //   labels: [
        //   'January',
        //   'February',
        //   'March',
        //   'April',
        //   'May',
        //   'June',
        //   'July',
        //   'August',
        //   'September',
        //   'October',
        //   'November',
        //   'December'
        // ],
        // datasets: [
        //   {
        //     label: 'Data One',
        //     backgroundColor: '#f87979',
        //     data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        //   }
        // ]
        // },

        // chartOptions: {
        //   responsive: true,
        //   indexAxis: 'y',
        //   plugins: {
        //     zoom: {
        //       zoom: {
        //         wheel: {
        //           enabled: true,
        //         },
        //         pinch: {
        //           enabled: true
        //         },
        //         mode: 'xy',
        //       }
        //     }
        //   }
        // },
      }
    },

  }
</script>

<style scoped  lang="scss">

</style>
